// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

import "../stylesheets/application.scss";
import "bootstrap";
// import "../scripts/stripe.js"
// import "trix"
// import "@rails/actiontext"
// import ReactOnRails from 'react-on-rails';
// import {CustomCard, CustomAdminCard} from '../cms/pages/Blog/components/CustomCard.jsx'
// import {CustomForm} from '../cms/pages/Blog/CustomForm.jsx'
// import ArticleShow from '../cms/pages/Blog/ArticleShow.jsx'
// import { TutoIndex } from "../cms/pages/Tuto/TutoIndex.jsx";
// import { ArticleIndex } from "../cms/pages/Blog/ArticleIndex.jsx";
// import {FaqTopicsIndex} from '../cms/pages/Faq/FaqTopicsIndex.jsx';


// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({

});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


